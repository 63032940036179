import * as System from "@harborschool/lighthouse"
import { useTheme } from "@harborschool/lighthouse"
import React from "react"
import { useIntl } from "../../../plugins/gatsby-plugin-intl-with-root"
import * as Illust from "../../assets/illust"
import { COURSES, COURSES_KO } from "../../constants"
import { ShareFlexPropsWithChildren } from "../../utils"
import { CourseCard } from "./course-card"

export const CourseCards = ({ ...props }) => {
  const theme = useTheme()
  const intl = useIntl()
  const courses = intl.locale === "ko" ? COURSES_KO : COURSES

  return (
    <ShareFlexPropsWithChildren {...props}>
      {courses
        .filter(
          course => course.status === "public" || course.status === "draft"
        )
        .map((course, id) => {
          const CourseIllust = Illust[course.illust]
          return (
            <System.FlexItem key={id}>
              <CourseCard
                courseSlug={course.key}
                courseTitle={course.title}
                courseExcerpt={course.excerpt}
                label={course.label}
                illust={course.illust && <CourseIllust />}
                waitList={course.status === "draft"}
              />
            </System.FlexItem>
          )
        })}
    </ShareFlexPropsWithChildren>
  )
}

CourseCards.defaultProps = {}
