import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import * as System from "@harborschool/lighthouse"
import { useTheme } from "@harborschool/lighthouse"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { userState, modalState } from "../../store"
import {
  navigate,
  useIntl,
} from "../../../plugins/gatsby-plugin-intl-with-root"
import { useFirstPost } from "../../hooks/post-contents/use-first-post"
import { sizeBg, guideBg, isClient, DesktopView, MobileView } from "../../utils"
import { MODAL, HEADER_LOGO_HEIGHT } from "../../constants"
import { CourseCards } from "../../components/course-cards"
import * as Illust from "../../assets/illust"

const HomePage = () => {
  const theme = useTheme()
  const sharedProps = { $theme: theme }
  const user = useRecoilValue(userState)
  const firstPost = useFirstPost()
  const headerSize = `${HEADER_LOGO_HEIGHT}px + ${theme.sizing.scale900} * 2`
  const intl = useIntl()

  return (
    <System.Block
      position="relative"
      overrides={{
        Block: {
          // overflow: "hidden",
          padding: `0 ${theme.sizing.scale2400}`,
          [theme.mediaQuery.large]: {
            height: "initial",
            overflowY: "initial",
            padding: `0 ${theme.sizing.scale800}`,
          },
        },
      }}
    >
      <System.Spacing height="scale1600" backgroundColor={sizeBg} />
      <System.Block
        display="grid"
        position="relative"
        overrides={{
          Block: {
            gridTemplateColumns: "3fr 1fr",
            gap: "20px",
            [theme.mediaQuery.xLarge]: {
              gridTemplateColumns: "7fr 4fr",
            },
            [theme.mediaQuery.large]: {
              gridTemplateColumns: "1fr",
              gap: "0px",
            },
          },
        }}
      >
        {/* Column Left */}
        <System.Block
          position="relative"
          backgroundColor={guideBg}
          overrides={{
            Block: {
              height: `calc(100vh - (${headerSize}) - ${theme.sizing.scale1600} * 2)`,
              [theme.mediaQuery.large]: {
                height: "initial",
              },
            },
          }}
        >
          <DesktopView margin={`0 auto`} height="100%">
            <System.Block height="85%" position="relative">
              <Illust.Sitting
                style={{
                  height: "100%",
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              />
            </System.Block>
          </DesktopView>
          <MobileView>
            <Illust.Sitting />
          </MobileView>
          <System.Block
            backgroundColor={sizeBg}
            overrides={{
              Block: {
                [theme.mediaQuery.large]: {
                  height: theme.sizing.scale1200,
                },
              },
            }}
          />

          <System.FlexBox
            columnGap={theme.sizing.scale700}
            alignItems="flex-end"
            position="absolute"
            overrides={{
              Block: {
                left: theme.sizing.scale900,
                bottom: theme.sizing.scale900,
                [theme.mediaQuery.large]: {
                  display: "block",
                  position: "relative",
                  left: 0,
                  bottom: 0,
                },
              },
            }}
          >
            <System.FlexItem>
              <System.DisplayXSmall
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                overrides={{
                  Block: {
                    [theme.mediaQuery.xLarge]: {
                      maxWidth: "300px",
                    },
                    [theme.mediaQuery.large]: {
                      maxWidth: "initial",
                      whiteSpace: "initial",
                    },
                  },
                }}
              >
                {intl.formatMessage({ id: "pages.home.site_title" })}
                <br />
                {intl.formatMessage({ id: "pages.home.now_launched" })}
              </System.DisplayXSmall>
            </System.FlexItem>

            <System.Block
              backgroundColor={sizeBg}
              overrides={{
                Block: {
                  [theme.mediaQuery.large]: {
                    height: theme.sizing.scale700,
                  },
                },
              }}
            />
            <System.FlexItem>
              <System.Button
                kind="primary"
                shape="pill"
                startEnhancer={
                  <System.Icons.Arrow
                    width={theme.sizing.scale300}
                    color={theme.colors.contentInversePrimary}
                  />
                }
                onClick={() => {
                  const firstItem = Object.keys(user.progress)[0]
                  const lastPost =
                    user.progress &&
                    `/${firstItem}${user.progress[firstItem].last}`
                  user.member ? navigate(lastPost) : navigate("/pricing")
                }}
              >
                {!user.member &&
                  intl.formatMessage({ id: "ui.cta.check_pricing" })}
                {user.member &&
                  intl.formatMessage({ id: "ui.cta.enter_course" })}
              </System.Button>
            </System.FlexItem>
          </System.FlexBox>
          <System.Block
            backgroundColor={sizeBg}
            overrides={{
              Block: {
                [theme.mediaQuery.large]: {
                  height: theme.sizing.scale1600,
                },
              },
            }}
          />
        </System.Block>
        {/* Column Right */}
        <System.Block
          overrides={{
            Block: {
              height: `calc(100vh - (${headerSize}) - ${theme.sizing.scale1600})`,
              padding: `0px ${theme.sizing.scale800}`,
              overflowY: "scroll",
              // add spacing to show card hover effect
              paddingTop: "100px",
              marginTop: "-100px",
              [theme.mediaQuery.large]: {
                height: "initial",
                padding: "0px",
                overflowY: "visible",
                marginTop: "0px",
              },
              "::-webkit-scrollbar": {
                width: "0px",
              },
            },
          }}
        >
          <System.FlexBox
            flexDirection="column"
            rowGap={theme.sizing.scale1600}
          >
            <CourseCards />
            <System.Spacing height="scale1600" backgroundColor={sizeBg} />
          </System.FlexBox>
        </System.Block>
      </System.Block>
    </System.Block>
  )
}

export default () => (
  <Layout hiddenFooter={true}>
    <SEO title="Home" />
    <HomePage />
  </Layout>
)
