import * as React from "react"
import * as System from "@harborschool/lighthouse"
import { useTheme } from "@harborschool/lighthouse"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { modalState, userState } from "../../store"
import {
  navigate,
  useIntl,
} from "../../../plugins/gatsby-plugin-intl-with-root"
import { MODAL, NAVIGATE } from "../../constants"
import { sizeBg } from "../../utils"
import { useFirstPosts } from "../../hooks"

export function CourseCard({
  courseSlug = "",
  courseTitle,
  courseExcerpt,
  label,
  illust,
  waitList = false,
}) {
  const theme = useTheme()
  const intl = useIntl()
  const user = useRecoilValue(userState)
  const setModal = useSetRecoilState(modalState)
  const firstPosts = useFirstPosts()
  const lastPost =
    user.progress && user.progress[courseSlug] && user.progress[courseSlug].last

  return (
    <System.Card
      label={label}
      title={courseTitle}
      onClick={() => {
        if (waitList) setModal(MODAL.WAIT_LIST)
        else {
          let postUrl
          if (lastPost) postUrl = `/${courseSlug}${lastPost}`
          else postUrl = findFirstPostByCourse({ firstPosts, courseSlug })
          navigate(postUrl, {
            state: { from: NAVIGATE.POST },
          })
        }
      }}
    >
      <System.CardBody>
        {courseExcerpt}
        <System.Spacing height="scale900" backgroundColor={sizeBg} />
        {illust}
      </System.CardBody>
      <System.CardAction>
        <System.Button
          kind="tertiary"
          startEnhancer={
            <System.Icons.Arrow
              width={theme.sizing.scale300}
              color={theme.colors.primary}
            />
          }
        >
          {lastPost
            ? intl.formatMessage({ id: "ui.cta.continue_course" })
            : intl.formatMessage({ id: "ui.cta.enter_course" })}
        </System.Button>
      </System.CardAction>
    </System.Card>
  )
}

CourseCard.defaultProps = {}

function findFirstPostByCourse({ firstPosts, courseSlug }) {
  for (let i = 0; i < firstPosts.edges.length; i++) {
    const post = firstPosts.edges[i].node
    if (post.frontmatter.course === courseSlug) return post.fields.originalPath
  }
}
